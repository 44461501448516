import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, Switch, TextField, Checkbox, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import SearchableSelect from "react-select";

import {
  handleAllocateRenting,
  selectState,
  showAllocateModal,
} from "./rentingSlice";
import { ListingStatus } from "../../../app/constants";
import { getListings, getListingsWithRentingTerminating } from "../../../api/listingsAPI";
import { getAllParkingAreas } from "../parkingArea/parkingAreaAPI";
import useToasts from "../../../hooks/useToasts";

const AllocateRentingModal = () => {
  const state = useSelector(selectState);
  const renting = state.selectedRenting;
  const dispatch = useDispatch();
  const { toastError } = useToasts();

  const [parkingAreas, setParkingAreas] = useState([]);
  const [vacantListings, setVacantListings] = useState([]);
  const [terminatingListings, setTerminatingListings] = useState([]);
  const [displayListings, setDisplayListings] = useState([]);

  const [areaId, setAreaId] = useState("");
  const [areaName, setAreaName] = useState("");
  const [listingId, setListingId] = useState("");
  const [listingName, setListingName] = useState("");
  const [isPreAllocate, setIsPreAllocate] = useState(false);
  const [monthlySellPrice, setMonthlySellPrice] = useState(null);

  const [sendNotification, setSendNotification] = useState(!renting.isSuppressNotification);

  useEffect(() => {
    const handleGetParkingAreas = async () => {
      const response = await getAllParkingAreas(0, 999999, "desc", "createdAt");
      const { content } = response.data;
      setParkingAreas(content);

      const requestedArea = content.filter(
        (area) => area.id === state.selectedRenting.requestedParkingAreaId
      )[0];
      setAreaId(requestedArea.id);
      setAreaName(requestedArea.name);
    };

    const handleGetVacantListings = async () => {
      const response = await getListings(0, 999999, "desc", "createdAt", ListingStatus.VACANT);
      const { content } = response.data;
      setVacantListings(content);
    };

    const handleGetTerminatingListings = async () => {
      const response = await getListingsWithRentingTerminating(0, 999999, "desc", "createdAt", `${ListingStatus.OCCUPIED},${ListingStatus.ACTIVE}`, null, null);
      const { content } = response.data;
      setTerminatingListings(content);
    };

    handleGetParkingAreas();
    handleGetVacantListings();
    handleGetTerminatingListings();
  }, [state.selectedRenting.requestedParkingAreaId]);

  useEffect(() => {
    var listings;
    if (isPreAllocate) {
      listings = terminatingListings.filter(
        (listing) => listing.parkingAreaId === areaId
      );
    } else {
      listings = vacantListings.filter(
        (listing) => listing.parkingAreaId === areaId
      );
    }

    setDisplayListings(listings);
    setListingId("");
    setListingName("");
  }, [areaId, isPreAllocate, vacantListings, terminatingListings]);

  const validateFields = () => {
    if (!listingId || !monthlySellPrice) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        const payload = {
          listingId,
          isPreAllocate,
          sellPrices: {
            monthly: monthlySellPrice * 100,
          },
          isSuppressNotification: !sendNotification,
        };
        dispatch(handleAllocateRenting(payload));
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="allocate-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showAllocateModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Allocate Renting</p>
          <Grid container spacing={2}>
            <Grid item xs={12} zIndex={999}>
              <p className="my-[5px] text-[#929292] text-[14px]">
                Parking Area
              </p>
              <SearchableSelect
                placeholder="Parking Area"
                value={{ value: areaId, label: areaName }}
                onChange={(e) => {
                  setAreaId(e.value);
                  setAreaName(e.label);
                }}
                options={[
                  ...parkingAreas.map((area) => {
                    return {
                      label: area.name,
                      value: area.id,
                    };
                  }),
                ]}
              />
            </Grid>
            <Grid item xs={12} zIndex={998}>
              <p className="my-[5px] text-[#929292] text-[14px]">
                {isPreAllocate ? "Terminating" : "Vacant"} Parking Bay
              </p>
              <SearchableSelect
                placeholder="Listing"
                value={{ value: listingId, label: listingName }}
                onChange={(e) => {
                  setListingId(e.value);
                  setListingName(e.label);
                }}
                options={[
                  ...displayListings.map((listing) => {
                    return {
                      label: `${listing.parkingBay.bayNumber} - ${listing.parkingBay.address}`,
                      value: listing.id,
                    };
                  }),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="monthly-sell-price"
                label="Monthly Sell Price"
                onChange={(e) => setMonthlySellPrice(e.target.value)}
                value={monthlySellPrice ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} mt={1.3} ml={6}>
              <p className="mt-[5px] text-[#929292] text-[14px]">
                Pre-allocate
              </p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={isPreAllocate}
                    onChange={(e) => setIsPreAllocate(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                className="ml-[-10px]"
                component="label"
                container
                alignItems="center"
              >
                <Grid item>
                  <Checkbox
                    color="default"
                    checked={sendNotification}
                    onChange={(e) => setSendNotification(e.target.checked)}
                  />
                </Grid>
                <Grid item>Send Notification</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                Enabling Send Notification will auto-send notifications for future actions.
              </Typography>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Allocate
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default AllocateRentingModal;
